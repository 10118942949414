"use client";

import { createContext, useContext, useEffect, useState, ReactNode } from 'react';
import { auth } from '../lib/firebase'; 
import { signInAnonymously, onAuthStateChanged, User } from 'firebase/auth';

interface AuthContextType {
  user: User | null;
  loading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken()
        localStorage.setItem('token', token);
        setUser(user); 
      } else {
        try {
          const userCredential = await signInAnonymously(auth);
          setUser(userCredential.user);
          const token = await userCredential.user.getIdToken();
          localStorage.setItem('token', token);
        } catch (error) {
          console.error("Failed to sign in anonymously", error);
          localStorage.removeItem('token');
        }
      }
      setLoading(false);
    });

    return () => unsubscribe(); 
  }, []);

  const value = { user, loading };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};